
import { Mixins, Component } from 'vue-property-decorator';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import PromotionsHeader from '@/modules/cluster/components/promotions/promotions-header.vue';
import PromotionsClusterOtelMixin from '@/modules/open-telemetry/mixins/promotions/promotions-cluster-otel.mixin';
import PromotionsActions from '../components/promotions/promotions-actions.vue';
import PromotionsClusterList from '../components/promotions/promotions-cluster-list.vue';

@Component({
    components: {
        PromotionsActions,
        PromotionsClusterList,
        PromotionsHeader,
        PageWrapper,
    },
})
export default class PromotionsCluster extends Mixins(PromotionsClusterOtelMixin) {
    beforeMount() {
        this.clusterService.hotels = null;
    }

    destroyed() {
        this.clusterService.resetLoading();
    }
}
