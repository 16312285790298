import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ClusterPromotionsService, { ClusterPromotionsServiceS } from '@/modules/cluster/cluster-promotions.service';

import ClusterOtelMixin from '../common/cluster-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Mixin for promotion cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class PromotionsClusterOtel extends Mixins(ClusterOtelMixin) {
    @Inject(ClusterPromotionsServiceS)
    protected clusterPromotionsService!: ClusterPromotionsService;

    get payload() {
        const filterToBuild = ['provider', 'los', 'programView', 'month', 'year'];
        const settingsUnion = {
            ...this.clusterPromotionsService.settings,
            ...this.documentFiltersService.settings,
        } as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as any);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}
