
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import { Inject } from 'inversify-props';
import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import PromotionsService, { PromotionsServiceS } from '@/modules/promotions/promotions.service';
import ListHeader from '../_common/list-days-header.vue';
import HotelsList from '../_common/hotels-list/index.vue';
import ClusterPromotionsService, { ClusterPromotionsServiceS } from '../../cluster-promotions.service';
import ClusterService, { ClusterServiceS } from '../../cluster.service';
import WeekdayLetter from '../../filters/weekend-letter.filter';
import PromotionsDaysSet from './promotions-days-set.vue';
import ClusterPromotionsModel from '../../models/cluster-promotions.model';

@Component({
    components: {
        DotsSpinner,
        ListHeader,
        HotelsList,
    },
    filters: {
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },

        WeekdayLetter,
    },
})
export default class PromotionsClusterList extends Vue {
    @Inject(ClusterPromotionsServiceS) private clusterPromotionsService!: ClusterPromotionsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(PromotionsServiceS) private promotionsService!: PromotionsService;

    get hotels() {
        return this.clusterPromotionsService.hotels;
    }

    get pagesCount() {
        return this.clusterPromotionsService.hotelsCount;
    }

    get component() {
        return {
            element: PromotionsDaysSet,
            props: {},
        };
    }

    get headerData() {
        return [{
            value: 'hotelName',
            name: 'Hotel Name',
        }];
    }

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [{
                value: h.hotelName,
                onClick: () => this.toHotel(h),
            }],
            component: {
                element: PromotionsDaysSet,
                props: { hotelData: h },
            },
        }));
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    toHotel(hotelData: ClusterPromotionsModel) {
        const { id } = hotelData.compsetMain || {};
        const compsetToSet = id || hotelData.compsets[0]?.id;
        this.documentFiltersService.compsetId = compsetToSet;
        this.documentFiltersService.compsetIdFromHighLevel = compsetToSet;

        this.promotionsService.resetDocument();

        this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(hotelData.hotelId),
            },
        });
    }
}
